<template>
	<div>
		<div>
			<el-form :inline="true">
				<el-form-item>
					<el-select
						v-model="searchParams.parkId"
						:clearable="true"
						@change="SearchPageData"
						placeholder="请选择园区"
						class="centerSelInput"
					>
						<el-option v-for="item in ParkOption" :key="item.val" :label="item.text" :value="item.val">
						</el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item>
					<el-input
						:clearable="true"
						@clear="SearchPageData"
						v-model="searchParams.carType"
						placeholder="请输入车辆类型"
						class="centerInput"
					></el-input>
				</el-form-item>
				
				<el-form-item>
					<el-input
						:clearable="true"
						@clear="SearchPageData"
						v-model="searchParams.carNumber"
						placeholder="请输入车辆编号"
						class="centerInput"
					></el-input>
				</el-form-item>
				
				<el-form-item>
					<el-date-picker
						v-model="searchParams.dateRange"
						:clearable="true"
						@change="SearchPageData"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						value-format="yyyy-MM-dd"
						class="centerDatepicker"
					>
					</el-date-picker>
				</el-form-item>
				
				<el-form-item>
					<button class="centerCzbtn" @click="SearchPageData">搜索</button>
				</el-form-item>
			</el-form>
		</div>
		
		<Table
			:data1="historyListConfig.list"
			:columns1="historyListConfig.columns"
			:total="historyListConfig.total"
			:loading="historyListConfig.loading"
			:destroy-on-close="true"
			@getAjax="GetHistoryListData"
		/>
		
		<el-drawer
			:title="historyDetailConfig.title"
			:visible.sync="historyDetailConfig.visible"
			:size="historyDetailConfig.size"
			:destroy-on-close="true"
		>
			<div class="headDetail">
				<div class="detailQuery">
					<div>
						车辆编号: <span> {{ historyDetailData.carNumber }}</span>
					</div>
					<div>
						所属园区: <span> {{ historyDetailData.parkName }}</span>
					</div>
				</div>
				
				<el-date-picker
					v-model="historyDetailSearchParams.dateRange"
					:clearable="true"
					@change="GetHistoryDetailData"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					value-format="yyyy-MM-dd"
					class="centerDatepicker"
				></el-date-picker>
				
				<button class="centerCzbtn ml20" @click="GetHistoryDetailData">搜索</button>
			</div>
			
			<el-row :gutter="20" style="margin-top: 20px;">
				<el-col :span="14">
					<div class="map-container">
						<amap ref="gaodeMap" class="gaode-map">
						</amap>
						
						<div class="play-control-container" v-if="historyCarMovePlayControl.show">
							<div class="mask"></div>
							<div class="play-control">
								<div class="play">
									<i class="el-icon-video-play" @click="PlayCarMove" v-if="historyCarMovePlayControl.play === false"></i>
									<i class="el-icon-video-pause" @click="PauseCarMove" v-else></i>
								</div>
								<div class="speed">
									<el-dropdown trigger="click" placement="top" @command="SetCarMoveSpeed">
										<el-button type="text" class="btn-speed">{{ historyCarMovePlayControl.speedButtonText }}</el-button>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="16">x16</el-dropdown-item>
											<el-dropdown-item command="8">x8</el-dropdown-item>
											<el-dropdown-item command="4">x4</el-dropdown-item>
											<el-dropdown-item command="2">x2</el-dropdown-item>
											<el-dropdown-item command="1">正常</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="10">
					<el-card class="track-list-container">
						<p class="list-container-title">轨迹列表</p>
						
						<ul class="list-container-body" v-if="historyDetailData.trackList.length > 0">
							<li v-for="(item, idx) in historyDetailData.trackList" :key="idx">
								<div style="overflow: hidden;" @click="SelectedTrack(idx)">
									<div class="icon">
										<i class="el-icon-caret-right"></i>
									</div>
									<div class="info">
										<p class="track-title">{{ item.trackName }}</p>
										<p class="track-time">
											<span>{{ item.trackDate }}</span>
											<span>时长: {{ item.duration }}</span>
										</p>
									</div>
								</div>
							</li>
						</ul>
						
						<el-empty :image="emptyImage" :image-size="140" v-else></el-empty>
					</el-card>
				</el-col>
			</el-row>
			
			<el-row :gutter="20" style="margin-top: 20px;">
				<el-col :span="12">
					<el-tabs type="border-card">
						<el-tab-pane label="车辆数据">
							<div class="chart-list-container" v-if="historyDetailData.carDataChart.length > 0">
								<div class="chart-item" v-for="(item, idx) in historyDetailData.carDataChart" :key="idx">
									<div class="chart" :id="'CarDataChart_' + idx"></div>
								</div>
							</div>
							
							<el-empty :image="emptyImage" :image-size="100" v-else></el-empty>
						</el-tab-pane>
					</el-tabs>
				</el-col>
				<el-col :span="12">
					<el-tabs type="border-card">
						<el-tab-pane label="车辆事件列表">
							<Table
								:data1="historyDetailData.carEvent.list"
								:columns1="historyDetailData.carEvent.columns"
								:total="historyDetailData.carEvent.total"
								:loading="historyDetailData.carEvent.loading"
								:destroy-on-close="true"
								@getAjax="GetCarEventData"
								v-if="historyDetailData.carEvent.list.length > 0"
							/>
							<el-empty :image="emptyImage" :image-size="100" v-else></el-empty>
						</el-tab-pane>
						<el-tab-pane label="车辆告警列表">
							<Table
								:data1="historyDetailData.carWarning.list"
								:columns1="historyDetailData.carWarning.columns"
								:total="historyDetailData.carWarning.total"
								:loading="historyDetailData.carWarning.loading"
								:destroy-on-close="true"
								v-if="historyDetailData.carWarning.list.length > 0"
							/>
							<el-empty :image="emptyImage" :image-size="100" v-else></el-empty>
						</el-tab-pane>
					</el-tabs>
				</el-col>
			</el-row>
			
			<div style="width: 100%; height: 30px;"></div>
		</el-drawer>
	</div>
</template>

<script>
	import Table from '../../components/Table.vue'
	import {_ParkOption} from "../../api/global";
	import {_FindCarHistoryDetail, _FindCarHistoryList} from "../../api/dataAnalysis/history";
	import { Amap } from '@amap/amap-vue';
	import * as echarts from "echarts";
	
	export default {
		components: {
			Table, Amap
		},
		data() {
			return {
				emptyImage: require('../../assets/images/empty.png'),
				ParkOption: [],
				
				searchParams: {
					parkId: "",
					carType: "",
					carNumber: "",
					dateRange: [],
					page: 1,
					pageSize: 20,
				},
				
				historyListConfig: {
					total: 0,
					loading: false,
					columns: [
						{
							label: "车辆编号",
							prop: "carNumber",
							width: 200,
						},
						{
							label: "所属园区",
							prop: "parkName",
							width: 200
						},
						{
							label: "车辆类型",
							prop: "carChildType",
							width: 100,
						},
						{
							label: "行驶里程数",
							prop: "mileage",
							width: 120,
							render: (h, params) => {
								let obj = null;
								
								if (params.row.mileage !== null){
									let nMileage = parseInt(Number(params.row.mileage));
									obj = h('span', nMileage + 'km');
								}else{
									obj = h('span', '0km');
								}
								
								return obj;
							},
						},
						{
							label: "总事件数",
							width: 100,
							prop: "totalEventNum",
							render: (h, params) => {
								let obj = null;
								
								if (params.row.totalEventNum !== null){
									let nTotalEventNum = parseInt(Number(params.row.totalEventNum));
									
									obj = h('span', nTotalEventNum + '次');
								}else{
									obj = h('span', '0次');
								}
								
								return obj;
							},
						},
						{
							label: "V2X事件数",
							width: 100,
							prop: "v2xEventNum",
							render: (h, params) => {
								let obj = null;
								
								if (params.row.v2xEventNum !== null){
									let nV2xEventNum = parseInt(Number(params.row.v2xEventNum));
									
									obj = h('span', nV2xEventNum + '次');
								}else{
									obj = h('span', '0次');
								}
								
								return obj;
							},
						},
						{
							label: "总故障次数",
							width: 100,
							prop: "totalErrorNum",
							render: (h, params) => {
								let obj = null;
								
								if (params.row.totalErrorNum !== null){
									let nTotalErrorNum = parseInt(Number(params.row.totalErrorNum));
									
									obj = h('span', nTotalErrorNum + '次');
								}else{
									obj = h('span', '0次');
								}
								
								return obj;
							},
						},
						{
							label: "紧急制动次数",
							width: 100,
							prop: "emergencyBrakingNum",
							render: (h, params) => {
								let obj = null;
								
								if (params.row.emergencyBrakingNum !== null){
									let nEmergencyBrakingNum = parseInt(Number(params.row.emergencyBrakingNum));
									
									obj = h('span', nEmergencyBrakingNum + '次');
								}else{
									obj = h('span', '0次');
								}
								
								return obj;
							},
						},
						{
							label: "操作",
							render: (h, params) => {
								return h("div", [
									h(
										"span", {
											class: "operationA",
											on: {
												click: () => {
													this.OpenHistoryDetailDrawer(params.row);
													
													/*
													this.$router.push({
														path: '/historyBackDetail',
														query: {
															vehicleNumber: params.row.carNumber,
															park: params.row.parkName
														}
													});
													*/
												},
											},
										},
										"查看"
									),
								]);
							},
						}],
					list: [],
				},
				
				historyDetailConfig: {
					title: "车辆历史回溯详情",
					visible: false,
					size: "85%",
					total: 0,
					loading: false,
				},
				
				historyDetailSearchParams : {
					dateRange: [],
				},
				
				historyDetailData: {
					carNumber: "",
					parkName: "",
					trackList: [],
					carData: [],
					carEvent: {
						total: 0,
						page: 1,
						pageSize: 10,
						loading: false,
						columns: [
							{
								label: "事件名称",
								prop: "eventName",
							},
							{
								label: "开始时间",
								prop: "beginTime",
							},
							{
								label: "结束时间",
								prop: "endTime",
							},
						],
						list: [],
					},
					carWarning: {
						total: 0,
						page: 1,
						pageSize: 10,
						loading: false,
						columns: [
							{
								label: "告警名称",
								prop: "warningName",
							},
							{
								label: "开始时间",
								prop: "beginTime",
							},
							{
								label: "结束时间",
								prop: "endTime",
							},
							{
								label: "告警级别",
								prop: "warningLevel",
							},
						],
						list: [],
					},
					v2xData: [],
					carDataChart: [],
				},
				
				historyCarMovePlayControl: {
					show: true,
					play: false,
					speedButtonDefaultText: '倍速',
					speedButtonText: '倍速',
				},
				
			};
		},
		mounted() {
			this.InitPageData();
		},
		methods: {
			async InitPageData() {
				await this.GetParkOption();
				await this.GetPageData();
			},
			
			//获取园区选项
			async GetParkOption() {
				let res = await _ParkOption();
				
				if (res.success) {
					this.ParkOption = res.data;
				}
			},
			
			//region 历史列表
			async GetPageData() {
				this.historyListConfig.loading = true;
				
				let jsonParams = {
					parkId: this.searchParams.parkId,
					carType: this.searchParams.carType,
					carNumber: this.searchParams.carNumber,
					beginDate: '',
					endDate: '',
					page: this.searchParams.page,
					pageSize: this.searchParams.pageSize,
				};
				
				if (this.searchParams.dateRange === null){
					this.searchParams.dateRange = [];
				}
				
				if (this.searchParams.dateRange.length === 2){
					jsonParams.beginDate = this.searchParams.dateRange[0];
					jsonParams.endDate = this.searchParams.dateRange[1];
				}
				
				let res = await _FindCarHistoryList(jsonParams);
				
				if (res.success){
					this.historyListConfig.list = res.data.list;
					this.historyListConfig.total = res.data.total;
				}
				
				this.historyListConfig.loading = false;
			},
			
			//分页调用获取历史列表数据
			async GetHistoryListData(pagination){
				if (pagination) {
					if (pagination.page) {
						this.searchParams.page = pagination.page;
						this.searchParams.pageSize = pagination.limit;
					}
				}
				
				await this.GetPageData();
			},
			
			async SearchPageData(){
				this.searchParams.page = 1;
				await this.GetPageData();
			},
			//endregion 历史列表
			
			//region 历史详情
			async OpenHistoryDetailDrawer(historyData){
				this.historyDetailSearchParams.dateRange = this.searchParams.dateRange;
				this.historyDetailData.carNumber = historyData.carNumber;
				this.historyDetailData.parkName = historyData.parkName;
				
				this.historyDetailConfig.visible = true;
				
				this.GetHistoryDetailData();
			},
			
			async GetHistoryDetailData(){
				let jsonParams = {
					parkId: '',
					carType: '',
					carNumber: this.historyDetailData.carNumber,
					beginDate: '',
					endDate: '',
				};
				
				if (this.historyDetailSearchParams.dateRange === null){
					this.historyDetailSearchParams.dateRange = [];
				}
				
				if (this.historyDetailSearchParams.dateRange.length === 2){
					jsonParams.beginDate = this.historyDetailSearchParams.dateRange[0];
					jsonParams.endDate = this.historyDetailSearchParams.dateRange[1];
				}
				
				let res = await _FindCarHistoryDetail(jsonParams);
				
				if (res.success !== true){
					this.$message.warning(res.message);
					return false;
				}
				
				if (res.data.trackList !== null){
					this.historyDetailData.trackList = res.data.trackList;
					
					let nCount = this.historyDetailData.trackList.length;
					
					for (let i = 0; i < nCount; i++) {
						this.historyDetailData.trackList[i].duration = this.SecConverMinuteSec(this.historyDetailData.trackList[i].duration);
					}
				}
				
				if (res.data.carData !== null){
					this.historyDetailData.carData = res.data.carData;
					this.SetChartData();
					
					this.$nextTick(() => {
						this.SetChartObject();
					})
				}
				
				if (res.data.carEvent !== null){
					this.historyDetailData.carEvent.list = res.data.carEvent.list;
					this.historyDetailData.carEvent.total = res.data.carEvent.total;
				}
				
				if (res.data.carWarning !== null){
					this.historyDetailData.carWarning.list = res.data.carWarning.list;
					this.historyDetailData.carWarning.total = res.data.carWarning.total;
				}
			},
			
			//分页调用获取历史列表数据
			async GetCarEventData(pagination){
				if (pagination) {
					if (pagination.page) {
						this.historyDetailData.carEvent.page = pagination.page;
						this.historyDetailData.carEvent.pageSize = pagination.limit;
					}
				}
				
				await this.GetHistoryDetailData();
			},
			
			SelectedTrack(idx){
				let objSelectedTrack = this.historyDetailData.trackList[idx];
				
				if (objSelectedTrack.carMove === null){
					this.$message.warning("“"+ objSelectedTrack.trackName +"” " + "没有可供播放的轨迹数据！")
					return false;
				}
			},
			
			PlayCarMove(){
				this.historyCarMovePlayControl.play = true;
			},
			
			PauseCarMove(){
				this.historyCarMovePlayControl.play = false;
			},
			
			SetCarMoveSpeed(command){
				let sBtnText = this.historyCarMovePlayControl.speedButtonText;
				
				if (Number(command) > 1){
					sBtnText = 'x' + command
				}else{
					sBtnText = '倍速'
				}
				
				this.historyCarMovePlayControl.speedButtonText =  sBtnText;
			},
			
			SecConverMinuteSec(seconds){
				let nMinute = 0;
				let nSecond = 0;
				let nSecondTotal = Number(seconds);
				let sResult = '';
				
				nMinute = parseInt(nSecondTotal / 60);
				nSecond = nSecondTotal % 60;
				
				if (nSecond === 0){
					sResult = nMinute + '分';
				}else{
					sResult = nMinute + '分' + nSecond + '秒';
				}
				
				return sResult;
			},
			
			//region 图表
			//设置每日上线率图表
			SetChartData(){
				if (this.historyDetailData.carData.length <= 0) return false;
				
				let nCount = this.historyDetailData.carData.length;
				let Info = null;
				
				for (let i = 0; i < nCount; i++) {
					Info = this.historyDetailData.carData[i];
					
					if (Info.chartData.length > 0){
						let arrDate = [];
						let arrValue = [];
						
						for (const item of Info.chartData) {
							arrDate.push(item.date);
							arrValue.push(item.val);
						}
						
						this.historyDetailData.carDataChart.push({
							idx: i,
							title: Info.chartTitle,
							date: arrDate,
							val: arrValue,
						});
					}
				}
			},
			
			SetChartObject(){
				for (const item of this.historyDetailData.carDataChart) {
					let objChart = echarts.init(document.getElementById('CarDataChart_' + item.idx));
					this.DrawChart(objChart, '#4f84b4', item.title, '', item.date, item.val);
				}
			},
			
			//绘制图表
			DrawChart(chart, color, text, subtext, dateList, valList) {
				chart.setOption({
					title: {
						text,
						subtext,
						itemGap: 20,
						left: 20,
						top: 20,
						textStyle: {
							color: '#666',
							fontSize: 16,
							fontFamily: "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif",
						},
						subtextStyle: {
							color: '#333',
							fontSize: 12,
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: dateList,	//x轴：日期
						axisLabel: {
							interval: 0, //X轴信息全部展示
							textStyle: {
								align: 'center'
							}
						}
					},
					yAxis: {
						type: 'value',
					},
					grid: {
						top: '30%', // 等价于 y: '16%'
						left: '5%',
						right: '8%',
						bottom: '0%',
						containLabel: true
					},
					series: [{
						data: valList,	//y轴：值
						type: 'line',
						smooth: true,
						symbol: 'none',
						itemStyle: {
							normal: {
								lineStyle: {
									color
								}
							}
						}
					}]
				})
			}
			//endregion 图表
			//endregion 历史详情
		}
	}
</script>

<style lang="less" scoped>
	.headDetail {
		display: flex;
		
		.detailQuery {
			height: 36px; background-color: #fff; display: flex; align-items: center; padding: 0px 20px; color: #666;
			font-size: 14px; margin-right: 30px;
			
			div:last-child {
				margin-left: 40px;
			}
			
			span {
				color: #333;
				font-size: 14px;
				font-weight: 550;
				margin-left: 5px;
			}
		}
	}
	
	.map-container{
		width: 100%; height: 400px;
		
		.gaode-map{z-index: 0;}
		.play-control-container{
			width: 100%; overflow: hidden; position: relative; margin-top: -40px;
			
			.mask{width: 100%; height: 40px; background-color: rgba(0, 0, 0, 0.5); z-index: 1;}
			
			.play-control{
				width: 100%; height: 40px; position: relative; margin-top: -40px;
				
				.play{
					width: 50px; height: 40px; padding-top: 6px; text-align: center; float: left;
					
					i{font-size: 30px; color: #fff; cursor: pointer;}
				}
				
				.speed{
					width: 40px; height: 40px; line-height: 40px; text-align: center; float: right;
					
					.btn-speed{
						font-size: 14px; color: #fff; cursor: pointer;
					}
				}
			}
		}
	}
	
	.track-list-container{
		width: 100%; height: 400px; overflow-y: auto;
		
		.list-container-title{
			font-size: 16px; color: #666; padding: 0; margin: 0;
		}
		
		.list-container-body{
			width: 100%; overflow: hidden; padding: 0;
			
			li{
				width: 100%; overflow: hidden; list-style: none; cursor: pointer;
				
				.icon{
					width: 50px; height: 50px; line-height: 50px; background-color: #999; text-align: center; font-size: 40px;
					border-radius: 4px; -webkit-border-radius: 4px; -moz-border-radius: 4px; float: left; color: #e5e5e5;
				}
				
				.info{
					width: 80%; height: 50px; float: left; margin-left: 10px;
					
					p{padding: 0; margin: 0;}
					
					.track-title{
						font-size: 16px; color: #333; padding-bottom: 3px; padding-top: 1px;
					}
					
					.track-time{
						font-size: 14px; color: #333;
						
						span{padding-right: 10px;}
					}
				}
			}
		}
	}
	
	.car-data-container{
		width: 100%; height: 500px;
	}
	
	.chart-list-container {
		width: 100%; overflow: hidden;
		
		.chart-item{
			width: 100%; overflow: hidden; padding: 0; margin-bottom: 20px;
			
			p{padding: 0 20px; font-size: 16px; color: #333;}
			
			.chart{width: 100%; height: 200px;}
		}
	}
</style>
