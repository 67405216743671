import service from '@/until/api'

export function _FindCarHistoryList(data) {
    return service({
        url: '/report-data/carHistory/list',
        method: 'POST',
        data
    })
}

export function _FindCarHistoryDetail(data) {
    return service({
        url: '/report-data/carHistory/detail',
        method: 'POST',
        data
    })
}
